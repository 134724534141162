// Apine
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'

// Plugins

// Extend webpack window classes

// Initialize alpine
Alpine.plugin(persist)

// Plugins
import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'
import Plyr from 'plyr'

// Extend webpack window classes
window.Swiper = Swiper
window.Plyr = Plyr

// Add to window
window.Alpine = Alpine;
window.Alpine.start();